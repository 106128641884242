/**
 *	Styles
 */

@import "settings/index";
@import "tools/index";
@import "generic/index";
@import "elements/index";
@import "objects/index";
@import "components/index";
@import "vendors/index";
@import "utilities/index";
@import "node_modules/bootstrap/scss/utilities/api";
@import "custom/index";

.editable-input {
  border: none;
  outline: none;
  border-radius: 5px;

  &:focus {
    border: 1px solid rgb(133, 133, 133);
  }
}

.dropdown-menu {
  max-height: 300px;
  overflow-y: auto;
}

.rnc__notification-timer {
  transform: rotate(180deg);
}

.cursor-pointer {
  cursor: pointer;
}

.last-week-text-style {
  float: right;
  font-size: 9px;
  font-weight: 500;
  margin-top: -5px;
  color: #999999;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  font-weight: 700;
  color: #4d69fa;
  border-radius: 16px;
  padding: 9px;
  background-color: #edf0ff;
  display: inline-block;
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-color: #edf0ff;
}

.inputfile + label {
  cursor: pointer; /* "hand" cursor */
}

.inputfile:focus + label {
  /* outline: 1px dotted rgb(134, 132, 132); */
  /* outline: -webkit-focus-ring-color auto 5px; */
}

.upi_box{
  background: #fff3f3;
  border-radius: 15px;
  padding: 5px;
}